html,
body {
  /* background-color: #eeeeee; */
  color: green;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: none !important;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
  touch-action: manipulation;
  cursor: none !important;
}

strong {
  font-weight: 700 !important;
}

/* 
Normalize buttons
*/

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

button,
input {
  /* 1 */
  overflow: visible;
}

button,
select {
  /* 1 */
  text-transform: none;
}

.gs-dev-tools {
  z-index: 9999;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

.inline-link {
  text-decoration: underline;
}

#CybotCookiebotDialog {
  max-height: unset !important;
  min-height: unset !important;
  max-width: 50rem !important;
  min-width: unset !important;
  width: 50% !important;
  height: auto !important;
  border-radius: 24px !important;
  right: 0 !important;
  left: unset !important;
  top: 100% !important;
  transform: translate(0%, -100%) !important;
  cursor: auto !important;
  opacity: 0;
}

#CybotCookiebotDialog h2 {
  font-family: "__icaRubrik_cfdc58" !important;
  font-size: 26px !important;
  line-height: 1.2 !important;
}

#CybotCookiebotDialog .cb-dialog-content {
  font-family: "__icaText_38346f" !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
}

#CybotCookiebotDialogHeader,
#CybotCookiebotDialogPoweredByText,
#CybotCookiebotFader {
  display: none !important;
}

.CybotCookiebotDialogBodyButton {
  border-radius: 9999px !important;
}

#CybotCookiebotDialogBodyEdgeMoreDetailsLink,
#CybotCookiebotDialogNavDeclaration,
#CybotCookiebotDialog a {
  cursor: pointer !important;
}

#CybotCookiebotDialog[data-template="overlay"],
#CybotCookiebotDialog[data-template="popup"] {
  top: 50% !important;
  transform: translate(0%, -50%) !important;
}

.CybotCookiebotDialogBodyBottomWrapper {
  display: none !important;
}

#CybotCookiebotDialogBodyEdgeMoreDetailsLink {
  border-radius: 9999px !important;
  padding: 1rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #f6f6f6 !important;
  color: #141414 !important;
  font-weight: 600 !important;
  margin-top: 8px !important;
}

#CybotCookiebotDialogBodyEdgeMoreDetailsLink::after {
  display: none !important;
}

#CybotCookiebotDialog[data-template="popup"]
  #CybotCookiebotDialogBodyEdgeMoreDetailsLink {
  margin-top: 0 !important;
  margin-right: 8px !important;
}

@media (max-width: 1440px) {
  #CybotCookiebotDialog {
    width: 80% !important;
  }
}

@media (max-width: 1024px) {
  #CybotCookiebotDialogBodyEdgeMoreDetailsLink {
    margin-right: 8px !important;
    margin-top: 0 !important;
  }
}

@media (max-width: 768px) {
  #CybotCookiebotDialog {
    width: 100% !important;
  }

  #CybotCookiebotDialog[data-template="overlay"],
  #CybotCookiebotDialog[data-template="popup"] {
    height: 80vh !important;
  }

  #CybotCookiebotDialog h2 {
    font-family: "__icaRubrik_cfdc58" !important;
    font-size: 18px !important;
    line-height: 1.2 !important;
  }
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
/* ol, ul {
	list-style: none;
} */
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
@font-face {
font-family: '__icaRubrik_cfdc58';
src: url(/_next/static/media/9eed4649418168bd.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__icaRubrik_cfdc58';
src: url(/_next/static/media/d30eae8593149735.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__icaRubrik_Fallback_cfdc58';src: local("Arial");ascent-override: 99.46%;descent-override: 26.17%;line-gap-override: 0.00%;size-adjust: 95.52%
}.__className_cfdc58 {font-family: '__icaRubrik_cfdc58', '__icaRubrik_Fallback_cfdc58'
}

@font-face {
font-family: '__icaText_38346f';
src: url(/_next/static/media/fd78201b8705116b.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__icaText_38346f';
src: url(/_next/static/media/a574f0e1af14883e.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__icaText_38346f';
src: url(/_next/static/media/d8e140af48bee49c.p.otf) format('opentype');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__icaText_Fallback_38346f';src: local("Arial");ascent-override: 94.62%;descent-override: 24.90%;line-gap-override: 0.00%;size-adjust: 100.40%
}.__className_38346f {font-family: '__icaText_38346f', '__icaText_Fallback_38346f'
}

