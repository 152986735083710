html,
body {
  /* background-color: #eeeeee; */
  color: green;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: none !important;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
  touch-action: manipulation;
  cursor: none !important;
}

strong {
  font-weight: 700 !important;
}

/* 
Normalize buttons
*/

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

button,
input {
  /* 1 */
  overflow: visible;
}

button,
select {
  /* 1 */
  text-transform: none;
}

.gs-dev-tools {
  z-index: 9999;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

.inline-link {
  text-decoration: underline;
}

#CybotCookiebotDialog {
  max-height: unset !important;
  min-height: unset !important;
  max-width: 50rem !important;
  min-width: unset !important;
  width: 50% !important;
  height: auto !important;
  border-radius: 24px !important;
  right: 0 !important;
  left: unset !important;
  top: 100% !important;
  transform: translate(0%, -100%) !important;
  cursor: auto !important;
  opacity: 0;
}

#CybotCookiebotDialog h2 {
  font-family: "__icaRubrik_cfdc58" !important;
  font-size: 26px !important;
  line-height: 1.2 !important;
}

#CybotCookiebotDialog .cb-dialog-content {
  font-family: "__icaText_38346f" !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
}

#CybotCookiebotDialogHeader,
#CybotCookiebotDialogPoweredByText,
#CybotCookiebotFader {
  display: none !important;
}

.CybotCookiebotDialogBodyButton {
  border-radius: 9999px !important;
}

#CybotCookiebotDialogBodyEdgeMoreDetailsLink,
#CybotCookiebotDialogNavDeclaration,
#CybotCookiebotDialog a {
  cursor: pointer !important;
}

#CybotCookiebotDialog[data-template="overlay"],
#CybotCookiebotDialog[data-template="popup"] {
  top: 50% !important;
  transform: translate(0%, -50%) !important;
}

.CybotCookiebotDialogBodyBottomWrapper {
  display: none !important;
}

#CybotCookiebotDialogBodyEdgeMoreDetailsLink {
  border-radius: 9999px !important;
  padding: 1rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #f6f6f6 !important;
  color: #141414 !important;
  font-weight: 600 !important;
  margin-top: 8px !important;
}

#CybotCookiebotDialogBodyEdgeMoreDetailsLink::after {
  display: none !important;
}

#CybotCookiebotDialog[data-template="popup"]
  #CybotCookiebotDialogBodyEdgeMoreDetailsLink {
  margin-top: 0 !important;
  margin-right: 8px !important;
}

@media (max-width: 1440px) {
  #CybotCookiebotDialog {
    width: 80% !important;
  }
}

@media (max-width: 1024px) {
  #CybotCookiebotDialogBodyEdgeMoreDetailsLink {
    margin-right: 8px !important;
    margin-top: 0 !important;
  }
}

@media (max-width: 768px) {
  #CybotCookiebotDialog {
    width: 100% !important;
  }

  #CybotCookiebotDialog[data-template="overlay"],
  #CybotCookiebotDialog[data-template="popup"] {
    height: 80vh !important;
  }

  #CybotCookiebotDialog h2 {
    font-family: "__icaRubrik_cfdc58" !important;
    font-size: 18px !important;
    line-height: 1.2 !important;
  }
}
